<template>
  <div>
    Inventory box contains weight from the following Check-Ins:
    <router-link-id
      v-for="checkInId in context.data"
      :key="checkInId"
      name="check-in"
      :id="checkInId"
    />
  </div>
</template>

<script>
import RouterLinkId from '@/base/components/RouterLinkId'

export default {
  name: 'InventoryBoxContext',
  components: { RouterLinkId },
  props: {
    context: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
