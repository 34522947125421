<template>
  <v-container>
    <base-title class="pb-4">
      QR Code Lookup
      <template v-slot:subtitle>
        <em>{{ token }}</em>
      </template>
    </base-title>
    <div v-if="identifier">
      <v-list>
        <v-list-item
          v-for="(c, index) in context"
          :key="index"
        >
          <component
            :context="c"
            :is="contextTypeComponents[c.type]"
          />
        </v-list-item>
      </v-list>
    </div>
    <div v-else-if="err">
      {{ err }}
    </div>
    <div v-else>
      Unable to retrieve information for token: {{ $route.params.id }}
    </div>
  </v-container>
</template>

<script>
import axios from 'axios'
import CheckInSkidContext from './components/CheckInSkidContext'
import InventoryBoxContext from './components/InventoryBoxContext'

export default {
  name: 'IdentifierToken',
  components: {
    CheckInSkidContext,
    InventoryBoxContext
  },
  data: () => ({
    token: null,
    identifier: null,
    loading: false,
    err: null,
    context: null,
    // Map context type to component names
    contextTypeComponents: {
      check_in_skid: 'CheckInSkidContext',
      inventory_box: 'InventoryBoxContext'
    }
  }),
  mounted () {
    this.token = this.$route.params.id
    this.loading = true
    axios.get(`/api/identifier_token/${this.token}/`).then(res => {
      this.identifier = res.data
      this.context = res.data.context
    }).catch(err => {
      this.error = err.message
    }).finally(() => {
      this.loading = false
    })
  }
}
</script>
