<template>
  <div>
    View the related check-in:
    <v-btn
      small
      color="primary"
      :to="{ name: 'check-in', params: { id: context.data }}"
    >
      Check-In {{ context.data }}
    </v-btn>
  </div>
</template>

<script>

export default {
  name: 'CheckInSkidContext',
  props: {
    context: {
      type: Object,
      required: true
    }
  }
}
</script>
